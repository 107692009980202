var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Why objects expand on heating?")]),_vm._m(0),_c('h3',[_vm._v("Do all substances expand?")]),_vm._m(1),_c('h3',[_vm._v("Mathematical expression")]),_c('h5',[_vm._v("Linear Expansion")]),_vm._m(2),_c('h5',[_vm._v("Superficial Expansion")]),_vm._m(3),_c('h5',[_vm._v("Volumetric Expansion")]),_vm._m(4),_c('h3',[_vm._v("Isotropic materials")]),_c('p',[_vm._v("\n    Isotropic substances are those substances whose properties have the same value in all directions. Materials which are isotropic have a special relationship\n    between their expansion coeffients:\n    $$\\gamma=3\\alpha\\,\\,and\\,\\, \\beta=2\\alpha$$\n  ")]),_c('h3',[_vm._v("Density")]),_vm._m(5),_c('h3',{ref:"PlayGraph"},[_vm._v("\n    MagicGraph | Thermal Expansion of a Solid Bar\n  ")]),_c('p',[_vm._v("This MagicGraph offers an interactive illustration to help student visualize the thermal expansion/contraction of solids upon heating/cooling.")]),_c('h5',[_vm._v(" To Get Started")]),_c('p',[_vm._v("\n    The illustration shows a solid bar that is fixed at one end. The initial temperature of the solid bar is \\(T_i = 20^o C\\).\n    The temperature of the bar can be raised or decreased by adding or removing heat. Tap on\n  ")]),_c('p'),_c('v-responsive',[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('div',{staticClass:"edliy-box-about",attrs:{"id":"jxgbox1"}})])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n    You must have noticed how mercury rises in a thermometer when the temperature goes up.\n    This happens because, in general, solids and liquids expand when they are heated.\n    "),_c('br'),_vm._v("\n    In solids, the atoms are in a state of vibration motion around their equilibrium positions.\n    When the substance is heated, the energy from heating increases the vibrational motion of atoms.\n    The internal forces now are opposed by this motion. Finally the equilibrium is achieved when the temperature becomes steady\n    and at this new equilibrium, the atoms are at a greater distance than before &mdsh; thus the expansion. A similar process happens in liquids.\n  ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n    The short answer is "),_c('b',[_vm._v("No.")]),_vm._v(" Water is a wonderful example of a substance that expands on cooling from \\(0\\) to \\(4^{\\circ}C\\). This is because\n    of the extremely strong "),_c('i',[_vm._v("hydrogen bonding")]),_vm._v(" between oxygen and hydrogen.\n  ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n    If the temperature of a rod of length \\(l_0\\) changes by \\(\\Delta T\\), it has been found experimentally that\n    the change in the length of the rod is:\n    $$\\Delta l=l_0\\alpha\\Delta T$$\n    where, \\(\\alpha\\) is a constant called the "),_c('i',[_vm._v("linear expansion coefficient")]),_vm._v(" and it differs from material to material.\n  ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n    If the temperature of a plate of area \\(A_0\\) changes by \\(\\Delta T\\), the change in its area is:\n    $$\\Delta A=A_0\\beta\\Delta T$$\n    where, \\(\\beta\\) is called  the "),_c('i',[_vm._v("superficial expansion coefficient")]),_vm._v(" and just like the linear expansion coefficient, it depends on the material of the plate.\n  ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n    Just like the above two cases, the volume of a block made up of a certain material changes as follows when it is subjected to a temperature change of \\(\\Delta T\\):\n    $$\\Delta V = V_0\\gamma\\Delta T$$\n    where, \\(V_0\\) is the initial volume of the  block and \\(\\gamma\\) is the "),_c('i',[_vm._v("coefficient of volumetric expansion")]),_vm._v(".\n  ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n    Since the mass of a substance remains constant and its volume changes, the density also changes.\n    If a block is subjected to a temperature change \\(\\Delta T\\), its new volume is:\n    $$V'=V_0(1+\\gamma\\Delta T)$$\n    So the new density is:\n    $$\\rho '=\\frac{m}{V_0(1+\\gamma\\Delta T)}=\\frac{\\rho_0}{1+\\gamma\\Delta T}$$\n    When the value of \\(\\gamma\\) is small(in most practical cases it is), applying the "),_c('i',[_vm._v("binomial approximation")]),_vm._v(" can be\n    very useful.\n  ")])
}]

export { render, staticRenderFns }