// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeDash
} from '../../../common/edliy_utils-bernoulli';
const Boxes = {
  box1: function () {
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-2, 10, 10, -2],
  keepaspectratio: true, axis:false, ticks:{visible:false},
  grid:true, showCopyright:false, showNavigation:false,
  pan:{enabled:false}, zoom:{enabled:false}});
  brd1.suspendUpdate();
  brd1.options.board.minimizeReflow = 'none';
  brd1.options.text.highlight =false;
  brd1.options.text.fixed =true;
  brd1.options.text.highlight =false;
  brd1.options.text.cssStyle='fontFamily:Oswald',
  brd1.options.image.highlight =false;
  brd1.options.polygon.highlight =false;
  brd1.options.line.highlight =false;
  brd1.options.point.highlight =false;
  brd1.options.curve.highlight =false;
  brd1.options.slider.highlight =false;
  brd1.options.point.showInfoBox =false;
//brd1.options.layer['image'] =14;
//Title and tetxs
//Variables
  var l0 = 10;
  var maxPos = 7;
  var minPos = 4;
  var pos_previous = 5.8;
  var pos_actual;
  makeResponsive(brd1);
  placeTitle(brd1, 'Thermal Expansion of Solids', '');
  placeText(brd1, 4, 8., '&Delta; l = &alpha; (T_f -T_i) l_0');
//Title and subtitle
//var title1 = brd1.create('text', [5, 8.5, '<b> Thermal Expansion of Solids <b>'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);

//Sliders
var coef = placeSlider(brd1, 2, 6.5, 1, 25, 30, 2,'&alpha;','x10^-^6');
var finalTemp = placeSlider(brd1, 4., 2, -100, 20, 100, 3,'T_f',' ^oC');
placeRec(brd1, [-5, -5], [-0., -5], [0, 10], [-5, 10], 'green');
//var coef = brd1.create('slider',[[8,7],[10,7],[1,25,30]], {snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'&alpha;', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//var finalTemp = brd1.create('slider',[[2,7],[5,7],[-100,20,100]], {visible: false, baseline:{strokeColor:'grey'}, highline:{strokeColor:'red'},
// snapToGrid: false ,face:'square', size:4, strokeWidth: 2, name:'T_f', strokeColor:'black', fillColor: 'grey',
//label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
finalTemp.setAttribute({visible:false, highline:{strokeColor:'red'}});
//Container
var arrL0 = brd1.create('segment', [[0, 5], [5,5]], {fixed: true, strokeColor: 'black', visible:true, strokeWidth:1,
                                                                                                                highlightStrokeWidth: 2,
                                                                                                                lastArrow: {type: 2, size: 8, highlightSize: 6},
                                                                                                                touchLastPoint: false,
                                                                                                                firstArrow: {type: 2, size: 8}
});

var deltaX = brd1.create('segment', [[function(){ return l0/2}, 5.5], [function(){ return l0/2 + 75*coef.Value()*Math.pow(10, -6)*l0*(finalTemp.Value() - 20)},5.5]], {fixed: true, strokeColor: 'black', visible:true, strokeWidth:1,
                                                                                                                highlightStrokeWidth: 2,
                                                                                                                lastArrow: {type: 2, size: 8, highlightSize: 6},
                                                                                                                touchLastPoint: false,
                                                                                                                firstArrow: {type: 2, size: 8}
});

var seg = brd1.create('segment', [[0, 4], [function(){return l0/2 + 75*coef.Value()*Math.pow(10, -6)*l0*(finalTemp.Value() - 20)}, 4]],{fixed: true, strokeColor: function(){return perc2color()} , visible: true, strokeWidth: 70});


//Tetxs
var txt1 = brd1.create('text', [2.5, 5.2,  function(){return 'l_0 = ' + l0.toFixed(2)+' m'}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
var txt2 = brd1.create('text', [function(){return l0/2 + (75*coef.Value()*Math.pow(10, -6)*l0*(finalTemp.Value() - 20))/2}, 5.7,  function(){return '&Delta;l = ' + (coef.Value()*Math.pow(10, -6)*l0*(finalTemp.Value() - 20)).toFixed(4)+' m'}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}},);
var txt3 = brd1.create('text', [2.5, 2,  'T_i = 20 °C'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(13*brd1.canvasWidth/500.)}},);

var txtTemp = brd1.create('text', [4.2, -0.5,  function(){return finalTemp.Value(). toFixed(0) +'°C'}],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

//increase and decrease
var increase = brd1.create('image', ['/assets/plus.svg', [5., -1], [1, 1]], {visible: true, fixed: true});
var decrease = brd1.create('image', ['/assets/minuss.svg', [function(){return increase.X()-2.5}, function(){return increase.Y()}], [1 , 1]], {visible: true, fixed: true});

//Points
var points1 = [];
var points2 = [];
var points3 = [];

var c1 = brd1.create('circle', [[function(){return 0.005 + (100 + finalTemp.Value() )/5000  }, 3.5], [0, 3.5]], {visible: false, strokeColor: 'blue', fillColor:'blue', fixed: true});
var c2 = brd1.create('circle', [[function(){return 0.005 + (100 + finalTemp.Value() )/5000  }, 4], [0, 4]], {visible: false, strokeColor: 'blue', fillColor:'blue', fixed: true});
var c3 = brd1.create('circle', [[function(){return 0.005 + (100 + finalTemp.Value() )/5000  }, 4.5], [0, 4.5]], {visible: false, strokeColor: 'blue', fillColor:'blue', fixed: true});

var pt1 = brd1.create('glider', [0.1 , 3.5 , c1],{face:'o' , name:'', size:3, strokeColor: 'black' ,fillColor: 'grey' , fixed:true, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pt2 = brd1.create('glider', [0.1 , 4, c2],{face:'o' , name:'', size:3, strokeColor: 'black' ,fillColor: 'grey' , fixed:true, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pt3 = brd1.create('glider', [0.1 , 4.5, c3],{face:'o' , name:'', size:3, strokeColor: 'black' ,fillColor: 'grey' , fixed:true, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

points1.push(pt1);
points2.push(pt2);
points3.push(pt3);

//changeVibration();

function changeVibration(){

    var vibration = Math.round( 1000- (500 + 5*finalTemp.Value())  ) ;

    pt1.stopAnimation(-1, 10, 1);
    pt2.stopAnimation(-1, 10, 1);
    pt3.stopAnimation(-1, 10, 1);

    pt1.startAnimation(-10, 4, 0.1);
    pt2.startAnimation(-10, 4, 0.1);
    pt3.startAnimation(-10, 4, 0.1);


    console.log('vibr', vibration);
    console.log('temp', finalTemp.Value());

}

for(let i=1; i<11 ; i++){

    var pt11 = brd1.create('point', [function(){return points1[i-1].X() + (l0/2 + 75*coef.Value()*Math.pow(10, -6)*l0*(finalTemp.Value() - 20))/10.5 }, function(){return points1[i-1].Y()} ],{face:'o' , name:'', size:3, strokeColor: 'black' ,fillColor:'grey' , fixed:true, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var pt22 = brd1.create('point', [function(){return points2[i-1].X() + (l0/2 + 75*coef.Value()*Math.pow(10, -6)*l0*(finalTemp.Value() - 20))/10.5 }, function(){return points2[i-1].Y()}],{face:'o' , name:'', size:3, strokeColor: 'black' ,fillColor:'grey' , fixed:true, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
    var pt33 = brd1.create('point', [function(){return points3[i-1].X() + (l0/2 + 75*coef.Value()*Math.pow(10, -6)*l0*(finalTemp.Value() - 20))/10.5 }, function(){return points3[i-1].Y()}],{face:'o' , name:'', size:3, strokeColor: 'black' ,fillColor:'grey' , fixed:true, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

    points1.push(pt11);
    points2.push(pt22);
    points3.push(pt33);

}


//Heater Images
for(let i=0; i<6 ; i++){
    var fire = brd1.create('image', ['/assets/flame.svg', [function(){return i*(1+coef.Value()*75*0.000001*(finalTemp.Value()-20.-0.25))}, 2.5], [0.5, 0.5]], {visible: function(){if(finalTemp.Value()>20){return true} else{return false}}, fixed: true});
}
//Cooler images
for(let i=0; i<5 ; i++){
    var cold = brd1.create('image', ['/assets/ac.svg', [function(){return i*(1+coef.Value()*75*0.000001*(finalTemp.Value()-20.-0.25))}, 2.5], [0.5, 0.5]], {visible: function(){if(finalTemp.Value()<=0){return true} else{return false}}, fixed: true});
}
//Functions

function perc2color() {

    var perc =  ( finalTemp.Value() + 100 )/2;

    var r, g, b = 0;

    if( finalTemp.Value() > 0){

        r = Math.round(255);
        g = Math.round(255* (1 - perc/100));
        b = Math.round(255* (1 - perc/100));
    }
    else if(finalTemp.Value() < 0) {

        r = Math.round(255* (perc/100));
        g = Math.round(255* (perc/100));
        b = Math.round(255);
    }
    else{
        r = Math.round(192);
        g = Math.round(192);
        b = Math.round(192);
    }

  var h = r * 0x10000 + g * 0x100 + b * 0x1;
  return '#' + ('000000' + h.toString(16)).slice(-6);
}


function addTemperature(){

    changeVibration();

    if(pos_actual >= maxPos){
        pos_actual = maxPos;
    }
    else{
        pos_actual = pos_previous + 0.1;
    }
    finalTemp.moveTo([pos_actual , 7], 500);
    pos_previous = pos_actual;

}

function removeTemperature(){

    changeVibration();

    if(pos_actual <= minPos){
        pos_actual = minPos;
    }
    else{
        pos_actual = pos_previous - 0.1;
    }
    finalTemp.moveTo([pos_actual , 7], 500);
    pos_previous = pos_actual;
}

increase.on('down', addTemperature);
decrease.on('down', removeTemperature);

//Labels
increase.setLabel('Tap to raise temperature')
increase.label.setAttribute({visible:false, offset:[-5, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
increase.on('over', function () {this.label.setAttribute({visible:true});});
increase.on('out', function () {this.label.setAttribute({visible:false});});

decrease.setLabel('Tap to decrease temperature')
decrease.label.setAttribute({visible:false, offset:[-5, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
decrease.on('over', function () {this.label.setAttribute({visible:true});});
decrease.on('out', function () {this.label.setAttribute({visible:false});});
//
brd1.unsuspendUpdate();


    },
}
export default Boxes;
