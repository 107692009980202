<template>
  <div>
    <h3>Why objects expand on heating?</h3>
    <p>
      You must have noticed how mercury rises in a thermometer when the temperature goes up.
      This happens because, in general, solids and liquids expand when they are heated.
      <br>
      In solids, the atoms are in a state of vibration motion around their equilibrium positions.
      When the substance is heated, the energy from heating increases the vibrational motion of atoms.
      The internal forces now are opposed by this motion. Finally the equilibrium is achieved when the temperature becomes steady
      and at this new equilibrium, the atoms are at a greater distance than before &mdsh; thus the expansion. A similar process happens in liquids.
    </p>
    <h3>Do all substances expand?</h3>
    <p>
      The short answer is <b>No.</b> Water is a wonderful example of a substance that expands on cooling from \(0\) to \(4^{\circ}C\). This is because
      of the extremely strong <i>hydrogen bonding</i> between oxygen and hydrogen.
    </p>
    <h3>Mathematical expression</h3>
    <h5>Linear Expansion</h5>
    <p>
      If the temperature of a rod of length \(l_0\) changes by \(\Delta T\), it has been found experimentally that
      the change in the length of the rod is:
      $$\Delta l=l_0\alpha\Delta T$$
      where, \(\alpha\) is a constant called the <i>linear expansion coefficient</i> and it differs from material to material.
    </p>
    <h5>Superficial Expansion</h5>
    <p>
      If the temperature of a plate of area \(A_0\) changes by \(\Delta T\), the change in its area is:
      $$\Delta A=A_0\beta\Delta T$$
      where, \(\beta\) is called  the <i>superficial expansion coefficient</i> and just like the linear expansion coefficient, it depends on the material of the plate.
    </p>
    <h5>Volumetric Expansion</h5>
    <p>
      Just like the above two cases, the volume of a block made up of a certain material changes as follows when it is subjected to a temperature change of \(\Delta T\):
      $$\Delta V = V_0\gamma\Delta T$$
      where, \(V_0\) is the initial volume of the  block and \(\gamma\) is the <i>coefficient of volumetric expansion</i>.
    </p>
    <h3>Isotropic materials</h3>
    <p>
      Isotropic substances are those substances whose properties have the same value in all directions. Materials which are isotropic have a special relationship
      between their expansion coeffients:
      $$\gamma=3\alpha\,\,and\,\, \beta=2\alpha$$
    </p>
    <h3>Density</h3>
    <p>
      Since the mass of a substance remains constant and its volume changes, the density also changes.
      If a block is subjected to a temperature change \(\Delta T\), its new volume is:
      $$V'=V_0(1+\gamma\Delta T)$$
      So the new density is:
      $$\rho '=\frac{m}{V_0(1+\gamma\Delta T)}=\frac{\rho_0}{1+\gamma\Delta T}$$
      When the value of \(\gamma\) is small(in most practical cases it is), applying the <i>binomial approximation</i> can be
      very useful.
    </p><h3 ref="PlayGraph">
      MagicGraph | Thermal Expansion of a Solid Bar
    </h3>
    <p>This MagicGraph offers an interactive illustration to help student visualize the thermal expansion/contraction of solids upon heating/cooling.</p>
    <h5> To Get Started</h5>
    <p>
      The illustration shows a solid bar that is fixed at one end. The initial temperature of the solid bar is \(T_i = 20^o C\).
      The temperature of the bar can be raised or decreased by adding or removing heat. Tap on
    </p>
    </p>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>

<script>
import Boxes from './Boxes.js'

export default {
  name: 'ThermalExpansion',
  data () {
    return {
      v1: 1,
      P1: 1,
      rho: 1,
      v2: undefined,
      P2: undefined,
    }
  },
  created: function () {
    // Store mutations
    let title = 'Thermal Expansion';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: ' Statement of Bernoulli\'s Principle', img:'/assets/number-1.svg',action: () => this.goto('intro')},
      {title: 'Derivation of Bernoulli\'s Principle', img:'/assets/number-2.svg',action: () => this.goto('derivation')},
      {title: 'Continuity Equation', img:'/assets/number-3.svg',action: () => this.goto('continuity')},
      {title: 'Conditions for Applicability', img:'/assets/number-4.svg',action: () => this.goto('appl')},
      {title: 'MagicGraph', img:'/assets/touch.svg',action: () => this.goto('PlayGraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);

  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();

  },
  updated () {
    // MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  }
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
